/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import 'bolt.css';

body {
  max-width: 800px;
}

header {
  background: var(--background-main);
  margin-bottom: 20px;
  padding: 0.5rem;
}
main {
  padding: 0.5rem;
}
footer {
  background: var(--background-main);
  margin-top: 20px;
  padding: 0.5rem;
}

dialog {
  border-radius: var(--border-radius);
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
  animation: modal-open .4s ease;
}

dialog[open] {
  animation: modal-slide .5s ease;
  background: var(--background-body);
  box-shadow: 0 4px 16px rgba(0 0 0 / 16%);
  color: var(--text);
}

@keyframes modal-open {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes modal-slide {
  0% {
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0);
  }
}
