/**
 * Bolt.css
 * Version 0.5.0
 * https://github.com/tbolt/boltcss
 *
 * Sections
 * 1. Content sectioning
 * 2. Text content
 * 3. Inline text semantics
 * 4. Image and multimedia
 * 5. Tables
 * 6. Forms
 * 7. Interactive elements
 *
 */

 :root {
  --highlight-border-radius: 7px;
  --border-radius: 11px;

  --links: #0f6dff;
  --background-body: #fff;
  --background-main: #eaa671;
  --background-inputs: #fcfcfc;
  --text: #1c1d1e;
  --border: #dddddd;
  --focus-highlight: #b8b8b8;
  --shadow-color: #545454;
  --table-highlight: #f1f1f1;
  --select-icon-url: url("data:image/svg+xml,%3Csvg width='292' height='292' viewBox='0 0 292 292' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath id='Path' fill='%23222222' stroke='none' d='M 287 69 C 283.606537 65.469971 278.895844 63.513214 274 63.600006 L 18.4 63.600006 C 13.4 63.600006 9.1 65.400009 5.5 69 C 1.984143 72.328568 -0.005267 76.958466 -0 81.800003 C -0 86.800003 1.8 91.100006 5.4 94.699997 L 133.399994 222.600006 C 137 226.200012 141.199997 228 146.199997 228 C 151.199997 228 155.399994 226.200012 159 222.600006 L 287 94.600006 C 290.5 91.100006 292.399994 86.800003 292.399994 81.800003 C 292.399994 76.800003 290.5 72.600006 286.899994 69 Z'/%3E%3C/svg%3E");
}

@media (prefers-color-scheme: dark--) {
  :root {
    --links: #4589ee;
    --background-body: #0f0f0f;
    --background-main: #37170a;
    --background-inputs: #222;
    --text: #efefef;
    --border: #444;
    --focus-highlight: #888;
    --shadow-color: #bebebe;
    --table-highlight: #222;
    --select-icon-url: url("data:image/svg+xml,%3Csvg width='292' height='292' viewBox='0 0 292 292' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath id='Path' fill='%23ffffff' stroke='none' d='M 287 69 C 283.606537 65.469971 278.895844 63.513214 274 63.600006 L 18.4 63.600006 C 13.4 63.600006 9.1 65.400009 5.5 69 C 1.984143 72.328568 -0.005267 76.958466 -0 81.800003 C -0 86.800003 1.8 91.100006 5.4 94.699997 L 133.399994 222.600006 C 137 226.200012 141.199997 228 146.199997 228 C 151.199997 228 155.399994 226.200012 159 222.600006 L 287 94.600006 C 290.5 91.100006 292.399994 86.800003 292.399994 81.800003 C 292.399994 76.800003 290.5 72.600006 286.899994 69 Z'/%3E%3C/svg%3E");
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-size: 12pt;
  font-family:
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    "Helvetica Neue",
    Arial,
    sans-serif;
  color: var(--text);
  background: var(--background-body);
}

/* Content sectioning */
address {
  font-style: normal;
  line-height: 1.6rem;
}

/* Todo: Revisit this and try to find a way to handle margin depending on float direction  */
aside {
  width: 40%;
  padding: 0.84rem;
  margin: 0.16rem;
  font-style: italic;
  color: var(--text);
  background-color: var(--background-main);
  border-radius: var(--border-radius);
}

/* No styles provided */
footer {
}
header {
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
  font-weight: normal;
}

h6 {
  font-size: 1rem;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h1,
h2,
h3,
h4,
b,
strong,
th {
  font-weight: 700;
}

/* Not provided */
hgroup {
}

/* Not provided */
main {
}

/* Not provided */
nav {
}

/* Not provided */
section {
}

/* Text content */
blockquote {
  position: relative;
  padding-left: 1.5rem;
  margin: 0;
}

blockquote:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
  border-left: 7px solid var(--border);
  border-radius: 6px;
}

dd {
  margin-left: 0;
  padding-bottom: 11px;
}

dl {
}

dt {
  font-weight: bold;
}

figcaption {
  padding-top: 10px;
  font-size: 0.8rem;
}

figure {
  margin: 0;
}

hr {
  border: 2px solid var(--border);
}

ul,
ol {
}

li {
  line-height: 1.6em;
}

p {
  display: block;
  line-height: 1.6em;
}

/* Inline text elements */
a {
  color: var(--links);
}

a:active,
a:hover,
a:focus {
  text-decoration: none;
}

mark,
samp,
kbd,
code,
time {
  border-radius: var(--highlight-border-radius, 4px);
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

mark {
  background-color: #fffab7;
  padding: 3px 5px;
}

samp {
  display: inline-block;
  font-weight: bold;
  padding: 10px 20px;
  background-color: var(--background-main);
  color: var(--text);
}

kbd,
time {
  padding: 2px 4px;
  background-color: var(--background-main);
  color: var(--text);
}

code,
pre {
  font-size: 1em;
  padding: 2px 4px;
  background: var(--background-main);
  border: 1px solid darkgray;
  max-width: fit-content;
  overflow-x: auto;
}

pre > code {
  padding: 10px;
  border: 0;
  display: block;
  overflow-x: auto;
}

pre {
  margin: 0;
  border-radius: var(--border-radius);
}

sup,
sub {
  line-height: normal;
}

/* Image and multimedia */
audio,
img,
video {
  border-radius: var(--border-radius);
  max-width: 100%;
}

img {
  height: auto;
}

/* Tables */
table {
  width: fit-content;
  border: 1px solid var(--background-main);
  background: var(--background-main);
  border-radius: var(--border-radius);
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

th {
  background-color: var(--background-main);
}

td {
  background: var(--background-body);
}

td,
th {
  text-align: left;
  padding: 8px;
}

thead {
  border-collapse: collapse;
}

tfoot {
  border-top: 1px solid black;
}

table tr:hover td,
tbody tr:nth-child(even):hover td {
  background-color: var(--table-highlight);
}

/* Form elements */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
}

button,
select,
input[type="submit"],
input[type="button"],
input[type="checkbox"],
input[type="range"],
input[type="radio"] {
  cursor: pointer;
}

button {
  color: var(--text);
  background-color: var(--background-main);
  font-family: inherit;
  font-size: inherit;
  padding: 6px 15px 6px 15px;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0px 1px 1.5px rgba(158, 158, 158, 0.6);
}

button:active {
  box-shadow: none;
  border: 1px solid var(--border);
}

button:disabled,
button[disabled] {
  box-shadow: none;
  border: 1px solid var(--border);
  cursor: initial;
  opacity: 0.55;
}

label {
  display: block;
  max-width: fit-content;
}

input {
  font-size: 1em;
  background-color: var(--background-inputs);
  border: 1px solid var(--border);
  color: var(--text);
  margin: 6px 0px;
  padding: 11px;
  border-radius: var(--border-radius);
  max-width: fit-content;
  outline: none;
  display: inline-block;
  appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: middle;
  position: relative;
  margin-right: 0.33em;
  margin-top: 0.31em;
}

input[type="checkbox"] {
  border-radius: 7px;
}

input[type="radio"] {
  border-radius: 100%;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  border: 1px solid var(--links);
  background: var(--links);
}

input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

input[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

input[type="range"] {
  vertical-align: middle;
  padding: 0;
}

textarea {
  font-family: inherit;
  font-size: 1em;
  background-color: var(--background-inputs);
  border: 1px solid var(--border);
  padding: 11px;
  color: var(--text);
  border-radius: var(--border-radius);
  outline: none;
  /* resize: none;  Todo: research if there is a non-js way to style/move grippie */
  max-width: 100%;
}

select {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  color: var(--text);
  padding: 0.6em 2em 0.5em 0.8em;
  margin: 6px 0px;
  max-width: fit-content;
  box-sizing: border-box;
  border: 1px solid var(--border);
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  appearance: none;
  background-color: var(--background-inputs);
  background-image: var(--select-icon-url);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

/* Todo: update styles when using multiple mode */
select:is([multiple]) {
  background: none;
  height: fit-content;
}

fieldset:focus-within,
input:focus-within,
textarea:focus-within,
select:focus-within {
  border-color: var(--focus-highlight);
}

fieldset:hover,
input:hover,
textarea:hover,
select:hover {
  border-color: var(--focus-highlight);
}

meter {
  height: 2.2em;
  vertical-align: middle;
}

fieldset {
  border: 1px var(--border) solid;
  border-radius: 6px;
  margin: 0;
  margin-bottom: 6px;
  padding: 10px;
  max-width: fit-content;
}

/* Interactive elements */
details {
  border: 1px solid #aaa;
  border-radius: 7px;
  padding: 0.5em 0.5em 0;
}

summary {
  font-weight: bold;
  margin: -0.5em -0.5em 0;
  padding: 0.5em;
}

details[open] {
  padding: 0.5em;
}

details[open] summary {
  border-bottom: 1px solid #aaa;
  margin-bottom: 0.5em;
}
